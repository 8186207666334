import React from 'react'
import styled from 'styled-components'
import {graphql, useStaticQuery, Link} from 'gatsby'
import Carousel from '@brainhubeu/react-carousel'
import quote from '../../images/icons/icon-quote.svg'
import '@brainhubeu/react-carousel/lib/style.css'

export default () => {
  const {testimonials} = useStaticQuery(graphql`
    {
      testimonials: allWordpressWpTestimonial {
        edges {
          node {
            title
            acf {
              name
              title
              content
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <StyledCarousel autoPlay={3000} infinite dots centered>
        {testimonials.edges.map(({node}, i) => (
          <Item>
            <Icon src={quote} />
            <Text>{node.acf.content}</Text>
            <EmptyCell />
            <Person>
              {node.acf.name}, <strong>{node.acf.title}</strong>
            </Person>
          </Item>
        ))}
      </StyledCarousel>
    </>
  )
}

const StyledCarousel = styled(Carousel)`
  display: block !important;

  & ul li div.BrainhubCarousel__dot:before {
    background: white;
    width: 10px;
    height: 10px;
  }

  & ul li div.BrainhubCarousel__dot--selected:before {
    background: red;
    width: 10px;
    height: 10px;
  }
`

const Item = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-columns: auto auto;
  grid-template-areas:
    'icon text'
    'empty person';
`

const Icon = styled.img`
  grid-area: icon;
  padding: 0 30px;
`
const EmptyCell = styled.div`
  grid-area: empty;
`
const Text = styled.div`
  grid-area: text;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 40px;
`
const Person = styled.div`
  font-size: 20px;
  grid-area: person;
  text-align: right;
`
